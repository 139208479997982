import React, { Component } from "react";
import { Table, Modal, Tooltip } from "antd";
import { NavLink, withRouter } from "react-router-dom";
import {
  QuestionCircleOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  MailOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { PageConst } from "./constant";
import { RenderDrop, Button } from "components/Form";
import { ButtonConst, RemoveConst } from "App/AppConstant";
import { getAuthRole, getAuthUserID } from "modules/helper";
import { configVar } from "modules/config";
const { Column } = Table;
const { confirm } = Modal;
var role = getAuthRole();
var userId = getAuthUserID();
role = role ? role : getAuthRole();

class TableUI extends Component {
  constructor(props) {
    super(props);
    this.state = { pageSize: 5, search: "", selectedRowKeys: [] };
  }
  componentDidMount() {
    role = role ? role : getAuthRole();
    userId = userId ? userId : getAuthUserID();
  }
  redirect = (link, id) => {
    try {
      const { match } = this.props;
      localStorage.url = match.url;
      this.props.history.push(link + window.btoa(id));
    } catch (error) {
      console.log(error);
    }
  };
  checkDate = (a) => {
    try {
      let date = new Date()?.getTime();
      let gtm = this.dateChange(a, true);
      let date2 = new Date(gtm)?.getTime();
      if (this.dateChange(date, true) === gtm) return false;
      return date >= date2;
    } catch (error) {
      console.log(error);
    }
  };
  actionUi = (a, edit, view) => {
    try {
      const { type, tab } = this.props;
      let data = []; // else if (type === "trading" && (a.type !== "Pending" || a.uid !== userId)) return "";
      if (type === "master" && !a.is_editable) return "";
      else if (type === "closure" && this.checkDate(a.to)) return "";
      else if (type === "user") {
        data = [
          <div onClick={() => this.redirect("/bank/", a.id)}>
            <div className="title">{PageConst.bd}</div>
          </div>,
          <div onClick={() => this.redirect("/education/", a.id)}>
            <div className="title">{PageConst.edu}</div>
          </div>,
          <div onClick={() => this.redirect("/employment/", a.id)}>
            <div className="title">{PageConst.emp}</div>
          </div>,
          <div onClick={() => this.redirect("/directorship/", a.id)}>
            <div className="title">{PageConst.dir}</div>
          </div>,
          a.uid === 67 ? (
            <div onClick={() => this.redirect("/holding/", a.id)}>
              <div className="title">{PageConst.hld}</div>
            </div>
          ) : null,
        ];
        if (tab !== "designated-person") data.splice(2, 1);
      } else if (type === "connected") {
        data = [
          <div onClick={() => this.redirect("/holding/", a.id)}>
            <div className="title">{PageConst.hld}</div>
          </div>,
          <div
            onClick={() => this.redirect("/connected-person-relation/", a.id)}
          >
            <div className="title">{PageConst.refName}</div>
          </div>,
        ];
      }
      return (
        <div className="flex actDiv" key="master">
          {view && (
            <div
              onClick={() =>
                this.props.viewCol(
                  type === "upsiInfo" || type === "closure"
                    ? a.id
                    : a.date1?.split("T")[0]
                )
              }
            >
              {this.tooltip(ButtonConst.view, <EyeOutlined />)}
            </div>
          )}
          {type !== "upsiInfo" && !edit && role === 66 && (
            <div onClick={() => this.props.editCol(window.btoa(a.id))}>
              {this.tooltip(ButtonConst.edit, <EditOutlined />)}
            </div>
          )}
          {type !== "upsiInfo" &&
            type !== "closure" &&
            !a.isSend &&
            ((role === 66 && type !== "declaration") ||
              (type === "declaration" && role !== 68 && a.type === "Pending") ||
              localStorage.url === "/profile") && (
              <div onClick={() => this.removeCol(a.id)}>
                {this.tooltip(ButtonConst.delete, <DeleteOutlined />)}
              </div>
            )}
          {(type === "smtp" || type === "closure") && (
            <div onClick={() => this.props.sendMail(a)}>
              {this.tooltip(
                type === "closure" ? ButtonConst.rsm : ButtonConst.sendMail,
                <MailOutlined />
              )}
            </div>
          )}
          {(type === "user" || type === "connected") && (
            <RenderDrop data={data} item={<UnorderedListOutlined />} />
          )}
          {(type === "declaration" || type === "forms") && (
            <Tooltip
              title={ButtonConst.download}
              placement="top"
              className="pointer"
            >
              <a
                href={configVar.BASE_URL + "files/" + a.dname}
                download={a.dname}
              >
                <DownloadOutlined />
              </a>
            </Tooltip>
          )}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };
  tooltip = (title, child) => (
    <Tooltip placement="top" title={title} className="pointer">
      {child}
    </Tooltip>
  );
  removeCol = async (i, all) => {
    try {
      const { tab } = this.props;
      confirm({
        title: RemoveConst.header + PageConst.rcd,
        icon: <QuestionCircleOutlined />,
        content: RemoveConst.deleteMessage + tab + RemoveConst.que,
        okText: RemoveConst.yes,
        okType: "danger",
        cancelText: RemoveConst.no,
        getContainer: () => document.getElementById("App"),
        onOk: () => {
          this.setState({ selectedRowKeys: [] });
          this.props.deleteCol(i, all);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  colNUI = (title, name) => (
    <Column
      title={title}
      dataIndex={name}
      sorter={(a, b) => a[name] - b[name]}
    />
  );
  colDUI = (title, name, txt3) => (
    <Column
      title={title}
      dataIndex={name}
      sorter={(a, b) => new Date(a[txt3]) - new Date(b[txt3])}
    />
  );
  colUI = (title, name) => (
    <Column
      title={title}
      dataIndex={name}
      sorter={(a, b) => a[name]?.localeCompare(b[name] ? b[name] : "")}
    />
  );
  actColUI = (edit, view) => (
    <Column
      width={100}
      title={PageConst.action}
      render={(record, i) => this.actionUi(record, edit, view)}
    />
  );
  btncol = (r, n, s) => (
    <Column
      title={PageConst.status}
      sorter={(a, b) => a.type?.localeCompare(b.type)}
      render={(record, i) => {
        if (!n && r && record.type === "Pending") {
          return (
            <>
              <Button
                color="answer"
                onClick={() => this.props.setclr(record.id, true)}
              >
                {ButtonConst.approve}
              </Button>
              <Button
                color="decline"
                className="mt-0"
                onClick={() => this.props.setclr(record.id, false)}
              >
                {ButtonConst.reject}
              </Button>
            </>
          );
        } else return <span className={record.type}>{record.type}</span>;
      }}
    />
  );
  downloadDoc = (name, mail) => (
    <Column
      title={PageConst.action}
      render={(record) => (
        <div className="flex">
          {record[name] &&
            this.tooltip(
              ButtonConst.download + PageConst[name],
              <a
                href={configVar.BASE_URL + "files/" + record[name]}
                download={record[name]}
              >
                <DownloadOutlined />
              </a>
            )}
          {mail && record.type === "Approved" && (
            <div
              className="ml-0"
              onClick={() => this.props.sendMail(record.id)}
            >
              {this.tooltip(ButtonConst.rsm, <MailOutlined />)}
            </div>
          )}
        </div>
      )}
    />
  );
  disData = (txt1, txt2, num, date, txt3) => {
    try {
      const { showCol } = this.props;
      let show = [];
      showCol?.forEach((a) => show.push(a.toLowerCase().replace(/ /g, "")));
      if (
        show?.includes(txt1?.replace(/ /g, "").toLowerCase()) ||
        show?.includes(txt2?.toLowerCase()) ||
        !showCol
      ) {
        if (!num && !date) return this.colUI(txt1, txt2);
        else if (date) return this.colDUI(txt1, txt2, txt3);
        return this.colNUI(txt1, txt2);
      }
    } catch (error) {
      console.log(error);
    }
  };
  columnUI = () => {
    try {
      const { type, tab, noAction } = this.props;
      return (
        <>
          <Column
            title={PageConst.sr}
            dataIndex="key"
            key="key"
            width={15}
            sorter={(a, b) => a.key - b.key}
          />
          {type === "master" && (
            <>
              {this.colUI(tab, "mname")}
              {role !== 68 && this.actColUI()}
            </>
          )}
          {type === "connRef" && (
            <>
              {this.colUI(PageConst.dpName, "dpName")}
              {this.colUI(PageConst.rel, "relation")}
              {role === 66 && this.actColUI(true)}
            </>
          )}
          {type === "connRef2" && (
            <>
              {this.colUI(PageConst.refName, "refName")}
              {role === 66 && this.actColUI(true)}
            </>
          )}
          {type === "benpo" && (
            <>
              <Column
                title={PageConst.date}
                sorter={(a, b) => new Date(a.date) - new Date(b.date)}
                render={(record, i) => {
                  return (
                    <NavLink to={"/benPOSList/" + record.date1}>
                      {record.hdate}
                    </NavLink>
                  );
                }}
              />
              {this.colUI(PageConst.isin, "isin")}
              {this.actColUI(true, true)}
            </>
          )}
          {type === "benpoList" && tab === "benpos" && (
            <>
              {this.disData(PageConst.dp, "dpid")}
              {this.disData(PageConst.clt, "clientID", true)}
              {this.disData(PageConst.name, "name")}
              {this.disData(PageConst.a1, "address1")}
              {this.disData(PageConst.a2, "address2")}
              {this.disData(PageConst.a3, "address3")}
              {this.disData(PageConst.a4, "address4")}
              {this.disData(PageConst.pincode, "pinCode", true)}
              {this.disData(PageConst.ts, "totalShare", true)}
              {this.disData(PageConst.tsp, "totalSharesPledged", true)}
              {this.disData(PageConst.tsl, "totalSharesunderLien", true)}
              {this.disData(PageConst.dt, "depositoryType")}
              {this.disData(PageConst.category, "category")}
              {this.disData(PageConst.pan, "pan")}
              {this.disData(PageConst.phone, "phone", true)}
              {this.disData(PageConst.email, "email")}
              {role !== 68 && this.actColUI(true)}
            </>
          )}
          {(type === "cpr" || type === "dpr") && (
            <>
              {this.colUI(PageConst.dp, "dpid")}
              {this.colNUI(PageConst.clID, "clientID")}
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.pan, "pan")}
              {this.colDUI(
                PageConst.previ + PageConst.holAsOn,
                "date2",
                "date3"
              )}
              {this.colNUI(PageConst.prevHol, "preHolding")}
              {this.colNUI(PageConst.buying + PageConst.plus, "buying")}
              {this.colNUI(PageConst.selling + PageConst.sub, "selling")}
              {this.colDUI(
                PageConst.present + PageConst.holAsOn,
                "date1",
                "date4"
              )}
              {this.colNUI(PageConst.presHol, "nowhold")}
              {this.colNUI(PageConst.preOfEqu, "equity")}
              {type === "dpr" && this.colUI(PageConst.category, "category")}
            </>
          )}
          {type === "hs" && (
            <>
              {this.colUI(PageConst.dp, "dpid")}
              {this.colNUI(PageConst.clID, "clientID")}
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.pan, "pan")}
              {this.colDUI(PageConst.holAsOn, "hdate", "date")}
              {this.colNUI(PageConst.shares, "totalShare")}
              {this.colNUI(PageConst.preOfEqu, "equity")}
              {this.colUI(PageConst.category, "category")}
            </>
          )}
          {type === "benpoList" && tab === "holders" && (
            <>
              {this.disData(PageConst.unitCode, "unitCode")}
              {this.disData(PageConst.secCode, "secCode")}
              {this.disData(PageConst.dpid, "hdpid")}
              {this.disData(PageConst.holderFol, "holderFol")}
              {this.disData(PageConst.holder, "holder")}
              {this.disData(PageConst.second, "second")}
              {this.disData(PageConst.third, "third")}
              {this.disData(PageConst.fhName, "fhName")}
              {this.disData(PageConst.haddress, "haddress")}
              {this.disData(PageConst.haddress1, "haddress1")}
              {this.disData(PageConst.haddress2, "haddress2")}
              {this.disData(PageConst.haddress3, "haddress3")}
              {this.disData(PageConst.haddress4, "haddress4")}
              {this.disData(PageConst.totalShare, "totalShar")}
              {this.disData(PageConst.holdMinor, "holdMinor")}
              {this.disData(PageConst.type, "type")}
              {this.disData(PageConst.pan, "panNo")}
              {this.disData(PageConst.phone, "hphone")}
              {this.disData(PageConst.fax, "fax")}
              {this.disData(PageConst.email, "hemail")}
              {role !== 68 && tab === "holders" && this.actColUI(true)}
            </>
          )}
          {type === "disclosure" && (
            <>
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.st, "securityType")}
              {this.colNUI(PageConst.ssa, "securities")}
              {this.colUI(PageConst.prc, "prc")}
              {this.colDUI(PageConst.startDate, "sDate", "startDate")}
              {this.colDUI(PageConst.endDate, "eDate", "endDate")}
              {this.colDUI(PageConst.trdDate, "tDate", "tradeDate")}
              {this.downloadDoc("note")}
              {/* {role === 66 && this.actColUI(true)} */}
            </>
          )}
          {type === "trading" && (
            <>
              {this.colUI(PageConst.user, "connected")}
              {this.colUI(PageConst.purpose, "purpose")}
              {this.colNUI(PageConst.pdm, "securityNo")}
              {this.colNUI(PageConst.ptp, "transPrice")}
              {this.colUI(PageConst.tra, "transaction")}
              {this.colDUI(PageConst.startDate, "sDate", "startDate")}
              {this.colDUI(PageConst.endDate, "eDate", "endDate")}
              {!noAction && this.colUI(PageConst.id, "pid")}
              {this.colDUI(PageConst.rsd, "cDate", "created_date")}
              {this.btncol(role === 66, noAction, true)}
              {this.downloadDoc("form", role === 66)}
              {/* {role === 66 && !noAction && this.actColUI()} */}
            </>
          )}
          {type === "closure" && (
            <>
              {this.colUI(PageConst.rec, "Selection")}
              {this.colUI(PageConst.purpose, "closureReason")}
              {this.colDUI(PageConst.from, "cfrom", "from")}
              {this.colDUI(PageConst.to, "cto", "to")}
              {role === 66 && this.actColUI(false)}
            </>
          )}
          {type === "declaration" && (
            <>
              {this.colUI(PageConst.name, "name")}
              <Column
                title={PageConst.fn}
                sorter={(a, b) => a.dname?.localeCompare(b.dname)}
                render={(record, i) => (
                  <a
                    href={configVar.BASE_URL + "files/" + record.dname}
                    download={record.dname}
                  >
                    {record.dname}
                  </a>
                )}
              />
              <Column
                title={PageConst.cb}
                sorter={(a, b) => a.unmae?.localeCompare(b.unmae)}
                render={(record, i) => <span>{record.unmae}</span>}
              />
              {this.btncol(role === 66, false, true)}
              {this.actColUI(true)}
            </>
          )}
          {type === "forms" && (
            <>
              <Column
                title={PageConst.nod}
                sorter={(a, b) => a.name?.localeCompare(b.name)}
                render={(record, i) => (
                  <a
                    href={configVar.BASE_URL + record.url}
                    download={record.url}
                  >
                    {record.name}
                  </a>
                )}
              />
              {this.actColUI(true)}
            </>
          )}
          {type === "upsiInfo" && (
            <>
              {this.disData(PageConst.utype, "type")}
              {this.disData(PageConst.purpose, "purpose")}
              {this.disData(PageConst.sender, "dpName")}
              {this.disData(PageConst.receiver, "receiver")}
              {this.disData(PageConst.mode, "mode")}
              {noAction && this.disData(PageConst.desc, "description")}
              {this.disData(
                PageConst.udate,
                "timeStamp",
                false,
                true,
                "created_date"
              )}
              {this.disData(PageConst.usdate, "UPSIDate", false, true, "sdate")}
              {role !== 68 && !noAction && this.actColUI(false, true)}
            </>
          )}
          {type === "holding" && (
            <>
              {this.colNUI(PageConst.sh, "securityNo")}
              {this.colNUI(PageConst.tl, "holdingHeld")}
              {this.colDUI(PageConst.date, "date1", "holdingDate")}
            </>
          )}
          {type === "edu" && (
            <>
              {this.colUI(PageConst.qua, "qualification")}
              {this.colUI(PageConst.uni, "university")}
              {this.colDUI(PageConst.ed, "date1", "enrollmentDate")}
              {this.colUI(PageConst.py, "passingYear")}
              {this.colUI(PageConst.grade, "grade")}
              {(role === 66 || localStorage.url === "/profile") &&
                this.actColUI(true)}
            </>
          )}
          {type === "bank" && (
            <>
              {this.colUI(PageConst.bank, "bankName")}
              {this.colUI(PageConst.branch, "branch")}
              {this.colUI(PageConst.ifsc, "ifsc")}
              {this.colUI(PageConst.account, "bankAccount")}
              {(role === 66 || localStorage.url === "/profile") &&
                this.actColUI(true)}
            </>
          )}
          {(type === "director" || type === "employee") && (
            <>
              {this.colUI(PageConst.companyName, "companyName")}
              {this.colUI(PageConst.designation, "designation")}
              {this.colDUI(PageConst.joiningDate, "date1", "joiningDate")}
              {this.colDUI(PageConst.exitDate, "date2", "exitDate")}
              {(role === 66 || localStorage.url === "/profile") &&
                this.actColUI(true)}
            </>
          )}
          {type === "user" && (
            <>
              {this.colUI(PageConst.name, "fullname")}
              <Column title={PageConst.email} dataIndex="email" />
              {this.colUI(PageConst.un, "userName")}
              {this.colUI(PageConst.pan, "pan")}
              {this.colUI(PageConst.category, "category")}
              {this.actColUI(role !== 66)}
            </>
          )}
          {type === "connected" && (
            <>
              {this.colUI(PageConst.name, "fullname")}
              <Column title={PageConst.email} dataIndex="email" />
              {this.colUI(PageConst.pan, "pan")}
              {role === 66 && this.colUI(PageConst.refName, "dpName")}
              {this.colUI(PageConst.city, "city")}
              {this.actColUI(role !== 66)}
            </>
          )}
          {type === "smtp" && (
            <>
              {this.colUI(PageConst.name, "fromName")}
              {this.colUI(PageConst.port, "port")}
              {this.colUI(PageConst.un, "username")}
              {role !== 68 && this.actColUI()}
            </>
          )}
          {type === "company" && (
            <>
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.city, "city")}
              {this.colUI(PageConst.pincode, "pin")}
              {this.colUI(PageConst.regAddress, "regAddress")}
              {this.colUI(PageConst.state, "state")}
              {role !== 68 && this.actColUI()}
            </>
          )}
          {type === "contra" && (
            <>
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.pan, "pan")}
              {this.colUI(PageConst.buy, "buy")}
              {this.colUI(PageConst.sale, "sale")}
              {this.colUI(PageConst.quat, "quality")}
              {this.colUI(PageConst.category, "category")}
              {this.colDUI(PageConst.date, "hdate", "date")}
            </>
          )}
          {(type === "pcv" || type === "vr") && (
            <>
              {this.colUI(PageConst.name, "name")}
              {this.colUI(PageConst.pan, "pan")}
              {this.colUI(PageConst.nap, "approval")}
              {this.colUI(PageConst.att, "purpose")}
              {this.colUI(PageConst.quantity, "qty")}
              {this.colUI(PageConst.buy, "buy")}
              {this.colUI(PageConst.sale, "sale")}
              {this.colUI(PageConst.prevq, "prevShare")}
              {this.colUI(PageConst.presHol, "totalShare")}
              {this.colUI(PageConst.vq, "variation")}
              {this.colDUI(
                PageConst.prec + " " + PageConst.startDate,
                "sDate",
                "startDate"
              )}
              {this.colDUI(
                PageConst.prec + " " + PageConst.endDate,
                "eDate",
                "endDate"
              )}
              {this.colDUI(
                PageConst.tra + " " + PageConst.date,
                "hdate",
                "date"
              )}
            </>
          )}
          {type === "audit" && (
            <>
              {this.colUI(PageConst.log, "text")}
              {this.colUI(PageConst.user, "user")}
              {this.colDUI(PageConst.date, "date1", "created_date")}
              {!noAction && (
                <Column
                  title={PageConst.action}
                  render={(record, i) => (
                    <div onClick={() => this.props.viewCol(record)}>
                      {this.tooltip(ButtonConst.view, <EyeOutlined />)}
                    </div>
                  )}
                />
              )}
            </>
          )}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };
  searchData = (data) => {
    try {
      const { search } = this.props;
      if (!search || search?.trim() === "") return data;
      else {
        let searchData = [];
        let display = [];
        data?.forEach((a) => {
          display.push(
            a.name,
            a.mname,
            a.folio,
            a.securityType,
            a.sDate,
            a.eDate,
            a.rel,
            a.refName,
            a.isin,
            a.address1,
            a.address2,
            a.address3,
            a.address4,
            a.pinCode,
            a.totalSharesPledged,
            a.totalSharesunderLien,
            a.depositoryType,
            a.phone,
            a.preHolding,
            a.date2,
            a.buying,
            a.selling,
            a.date1,
            a.dpid,
            a.clientID,
            a.hdate,
            a.totalShare,
            a.equity,
            a.unitCode,
            a.secCode,
            a.hdpid,
            a.holderFol,
            a.holder,
            a.second,
            a.third,
            a.fhName,
            a.haddress,
            a.haddress1,
            a.haddress2,
            a.haddress3,
            a.haddress4,
            a.totalShar,
            a.holdMinor,
            a.type,
            a.panNo,
            a.hphone,
            a.fax,
            a.hemail,
            a.dpName,
            a.receiver,
            a.mode,
            a.timeStamp,
            a.UPSIDate,
            a.securityName,
            a.security,
            a.holdHeld,
            a.qualification,
            a.university,
            a.enrollmentDate,
            a.passingYear,
            a.grade,
            a.bankName,
            a.branch,
            a.ifsc,
            a.bankAccount,
            a.companyName,
            a.designation,
            a.joiningDate,
            a.exitDate,
            a.fullname,
            a.userName,
            a.pan,
            a.category,
            a.email,
            a.fromName,
            a.port,
            a.username,
            a.city,
            a.pin,
            a.regAddress,
            a.state,
            a.connected,
            a.purpose,
            a.securityNo,
            a.transPrice,
            a.transaction,
            a.Selection,
            a.closureReason,
            a.cfrom,
            a.cto,
            a.dname
          );
          let array = [];
          display.forEach(
            (e) => e && e !== null && e.toString().length > 0 && array.push(e)
          );
          let matches = array.filter((s) =>
            s.toString().toLowerCase().includes(search.toString().toLowerCase())
          );
          display = [];
          if (matches && matches.length > 0) searchData.push(a);
        });
        return searchData;
      }
    } catch (error) {
      console.log(error);
    }
  };
  selectRow = (record) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else selectedRowKeys.push(record.key);
    this.setState({ selectedRowKeys });
  };
  onSelectedRowKeysChange = (selectedRowKeys) =>
    this.setState({ selectedRowKeys });
  deleteRow = (row) => {
    try {
      let srow = this.searchData();
      let allrow = [];
      row.forEach((a) => {
        allrow.push(srow[a - 1].id);
      });
      this.setState({ selectedRowKeys: [] });
      this.removeCol(allrow, true);
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date, format) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (format) return year + "-" + month + "-" + day;
    return date ? day + "-" + month + "-" + year : "";
  };
  render() {
    const { data, print, type } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        role !== 68 && {
          key: "odd",
          text: "Delete Selected Row",
          onSelect: () => this.deleteRow(selectedRowKeys),
        },
      ],
      onChange: this.onSelectedRowKeysChange,
    };
    data?.forEach((a, i) => {
      if (a) a.key = i + 1;
      if (type === "user" || type === "connected") {
        a.fullname = a.fatherName ? a.name + " " + a.fatherName : a.name;
        a.fullname = a.surname ? a.fullname + " " + a.surname : a.fullname;
      } else if (type === "director" || type === "employee") {
        a.date1 = this.dateChange(a.joiningDate);
        a.date2 = this.dateChange(a.exitDate);
      } else if (type === "edu") {
        a.date1 = this.dateChange(a.enrollmentDate);
      } else if (type === "holding") {
        a.date1 = this.dateChange(a.holdingDate) ;
      } else if (type === "disclosure" || type === "trading") {
        a.sDate = a.startDate ? this.dateChange(a.startDate) : "";
        a.eDate = this.dateChange(a.endDate);
        a.cDate = this.dateChange(a.created_date);
        a.tDate = a.tradeDate ? this.dateChange(a.tradeDate) : "";
        if (type === "disclosure") {
          a.prc = a.preClearance ? "Yes" : "No";
          a.sDate = a.preClearance ? a.sDate : "";
          a.eDate = a.preClearance ? a.eDate : "";
        }
      } else if (type === "pcv" || type === "vr") {
        a.sDate = this.dateChange(a.startDate);
        a.eDate = this.dateChange(a.endDate);
        a.hdate = this.dateChange(a.date);
      } else if (type === "closure") {
        a.cfrom = this.dateChange(a.from);
        a.cto = this.dateChange(a.to);
      } else if (type === "upsiInfo") {
        a.timeStamp =
          this.dateChange(a.created_date) +
          " " +
          new Date(a.created_date).toLocaleTimeString();
        a.UPSIDate = a.sdate
          ? this.dateChange(a.sdate) +
            " " +
            new Date(a.sdate).toLocaleTimeString()
          : "";
      } else if (
        type === "benpo" ||
        type === "benpoList" ||
        type === "hs" ||
        type === "contra"
      ) {
        a.hdate = this.dateChange(a.date);
      } else if (a && (type === "cpr" || type === "dpr")) {
        a.date1 = this.dateChange(a.date3);
        a.date2 = this.dateChange(a.date4);
      } else if (type === "audit")
        a.date1 =
          this.dateChange(a.created_date) +
          " " +
          new Date(a.created_date).toLocaleTimeString();
    });
    let viewData = this.searchData(data);
    return (
      <>
        <Table
          dataSource={viewData}
          size="middle"
          className="nTable"
          rowClassName={print ? "" : "anime"}
          rowSelection={type === "benpoList" && rowSelection}
          onRow={(record) => ({ onClick: () => this.selectRow(record) })}
        >
          {this.columnUI()}
        </Table>
      </>
    );
  }
}
export default withRouter(TableUI);
