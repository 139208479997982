import React, { Component } from "react";
import * as Yup from "yup";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";

import { PageConst } from "./constant";
import { sendmail } from "redux/smtp/actions";
import { getUserList } from "redux/user/actions";
import { ButtonConst, Holidays } from "App/AppConstant"; //FormValid
import { getTemplateInfoList } from "redux/templates/actions";
import { getUpsiInfoById, updateUpsiInfo } from "redux/upsiInfo/actions";
import { getConRefList } from "redux/connRef/actions"; //getConRefBydpId
import {
  addClosure,
  getClosureById,
  updateClosure,
} from "redux/closure/actions";
import {
  Label,
  Input,
  Button,
  LayOut,
  DatePicker,
  MultiSelect,
} from "components/Form"; //Checkbox, DateTimePicker
import { getAuthRole, getAuthUserID } from "modules/helper";

var userId = getAuthUserID();
var role = getAuthRole();
const ValidationSchema = Yup.object().shape({
  closureReason: Yup.string().trim().nullable().required(" "), // .matches(/^[aA-zZ0-9 ]+$/, FormValid.charValid),
  from: Yup.date().nullable().required(" "), // Selection: Yup.string().trim().required(" "),
  to: Yup.date()
    .nullable()
    .required(" ")
    .when("from", (from, schema) => from && schema.min(from, PageConst.mDate)),
});

class AddClosure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      serror: false, // checked: false,errDelar: false,
      updateUPSI: false,
      id: 0,
      cid: 0,
      upid: 0,
      rids: [],
      ulist: [],
      clist: [],
      uaData: [],
      idData: [],
      cpData: [],
      dpData: [],
      disData1: [],
      usrData: [],
      disData: [],
      cRefList: [],
      closerMail: [],
      init: {
        person: [],
        person2: [],
        closureReason: "",
        sid: "",
        from: new Date().toISOString().split("T")[0],
        to: new Date().toISOString().split("T")[0],
      },
    }; //, resultDate: ""
  }
  async componentDidMount() {
    try {
      const { match } = this.props;
      role = role ? role : getAuthRole();
      userId = userId ? userId : getAuthUserID();
      await this.props.getConRefList(userId); //if (role === 67)
      await this.props.getUserList();
      await this.props.getTemplateInfoList();
      if (match?.path?.toLowerCase().includes("/edit-")) {
        this.setState({ edit: true, cid: window.atob(match?.params?.id) });
        await this.props.getClosureById(window.atob(match?.params?.id));
      } else if (match?.path?.toLowerCase().includes("add-window-closure/")) {
        match?.params?.id &&
          (await this.props.getUpsiInfoById(window.atob(match?.params?.id)));
        this.setState({ updateUPSI: true, id: window.atob(match?.params?.id) });
      }
      this.checkHDate();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const { userList, closureData, upsiInfoData, templateList, connRefList } =
        this.props; // isSend, if (isSend !== prevProps.isSend)isSend && (await this.props.updateClosure({uid: userId,id: this.state.cid,isSend: 1}));
      if (connRefList !== prevProps.connRefList) {
        let clist2 = connRefList.filter((c) => c.is_deleted === 0);
        let clist = clist2.filter((c) => c.dpId === userId);
        this.setState({ clist, cRefList: clist2 });
      }
      if (userList !== prevProps.userList) {
        const { clist } = this.state; //&& a.id !== userId
        let list3 = userList?.filter((a) => a.is_deleted === 0);
        let urlist = list3?.filter((a) => a.uid !== 68);
        let nm = "",
          data = [],
          rids = [],
          dpData = [],
          cpData = [],
          uslist = []; // data2 = [],data3 = [],rmvData = [];
        if (role === 67) {
          let uhlist = userList?.filter((a) => a.id === userId);
          clist?.forEach((l) => {
            let s = userList?.find((a) => a.id === l.uId);
            if (s) uslist.push(s);
          });
          urlist = uhlist.concat(uslist);
        } // let usr = userList?.find((s) => s.id === userId);
        urlist.forEach((a, i) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name; // if (a.id === userId || usr.pan === a.pan) rmvData.push(i);
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.includes(a.value + pos + ")")) {
            if (data.includes(a.value + pos + ") 2")) {
              if (data.includes(a.value + pos + ") 3"))
                nm = a.value + pos + ") 4";
              else nm = a.value + pos + ") 3";
            } else nm = a.value + pos + ") 2";
          } else nm = a.value + pos + ")";
          a.uid < 68 && dpData.push(nm);
          a.uid > 68 && cpData.push(nm);
          data.push(nm);
          rids.push(a.id);
        }); // data?.forEach((a, i) => {if (!rmvData.includes(i)) {data2.push(a);data3.push(rids[i]);}}); //ulist: data2,rids: data3,
        this.setState({
          usrData: list3,
          uaData: data,
          idData: rids,
          dpData,
          cpData,
        });
      }
      if (upsiInfoData !== prevProps.upsiInfoData) {
        const { init } = this.state;
        let recive = upsiInfoData?.receiverId;
        recive = recive + "," + upsiInfoData.uid;
        init.person = this.findUser(recive); // init.person = upsiInfoData?.receiver?.split(",");
        init.person2 = this.findUser(recive, true);
        init.from = new Date(upsiInfoData.sdate);
        init.to = new Date(upsiInfoData.sdate);
        this.setState({
          init,
          upid: upsiInfoData.uid,
          disData: init.person,
          disData1: init.person2,
        });
      }
      if (closureData !== prevProps.closureData) {
        let val = {
          id: closureData.id,
          person: this.findUser(closureData?.sid), //closureData.person?.split(","),
          person2: this.findUser(closureData?.sid, true),
          closureReason: closureData.closureReason,
          from: new Date(closureData.from),
          to: new Date(closureData.to), // resultDate: closureData.resultDate ? new Date(closureData.resultDate) : ""
        };
        this.setState({
          init: val,
          disData: val.person,
          disData1: val.person2,
        });
      }
      if (templateList !== prevProps.templateList) {
        let match = templateList?.find(
          (a) => a.name === "Closure of Trading Window for All"
        );
        if (match) this.setState({ closerMail: match });
      }
    } catch (error) {
      console.log(error);
    }
  }
  checkHDate = () => {
    try {
      const { init } = this.state;
      if (this.disabledHDate(init.from)) init.from = "";
      if (this.disabledHDate(init.to)) init.to = "";
      this.setState({ init });
    } catch (error) {
      console.log(error);
    }
  };
  disabledHDate = (current) => {
    try {
      let match = Holidays?.find(
        (a) => new Date(a)?.toDateString() === new Date(current)?.toDateString()
      );
      return (
        match ||
        new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6
      );
    } catch (error) {
      console.log(error);
    }
  };
  findUser = (a, b) => {
    try {
      const { idData, uaData, usrData } = this.state;
      let rec = a?.split(",");
      let data = [];
      rec?.forEach((c) => {
        let usr = usrData.find((f) => f.id === parseInt(c));
        if ((!b && usr?.uid < 68) || (b && usr?.uid > 68)) {
          let idx = idData?.findIndex((b) => b === parseInt(c));
          if (idx > -1) data.push(uaData[idx]);
        }
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }; // changeDate = (a, setFieldValue) => {this.setState({ errDelar: false });setFieldValue("resultDate", a)}
  seall = (name, setFieldValue, zero) => {
    try {
      const { dpData, cpData } = this.state;
      setFieldValue(name, []);
      name === "person" && this.setState({ disData: [], serror: false });
      name !== "person" && this.setState({ disData1: [], serror: false });
      let list = name === "person" ? dpData : cpData;
      if (!zero) {
        setTimeout(() => {
          setFieldValue(name, list);
          name === "person" && this.setState({ disData: dpData });
          name !== "person" && this.setState({ disData1: cpData });
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  };
  dateChange = (date, format) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (format) return year + "-" + month + "-" + day;
    return date ? day + "-" + month + "-" + year : "";
  };
  sendToUser2 = (a) => {
    try {
      const { idData, uaData } = this.state;
      let uslist = [];
      a?.person?.forEach((c) => {
        let rdx = uaData?.findIndex((r) => r === c);
        rdx > -1 && uslist.push(idData[rdx]);
      });
      this.sendToUser(uslist, null, a.from, a.to);
    } catch (error) {
      console.log(error);
    }
  };
  sendToUser = (a, u, from, to) => {
    try {
      const { userList } = this.props;
      let data = [];
      if (u) {
        let usr = userList.find((d) => d.id === u);
        usr?.email && data.push(usr?.email);
      }
      a?.forEach((c) => {
        let user = userList.find((x) => x.id === c);
        if (user && !data.includes(user.email)) data.push(user.email);
      });
      this.sendMail(data, a.from ? a.from : from, a.to ? a.to : to);
    } catch (error) {
      console.log(error);
    }
  };
  sendMail = async (toData, from, to) => {
    try {
      const { closerMail } = this.state;
      if (toData?.length > 0 && closerMail) {
        let temp = closerMail.template;
        temp = temp?.replace("DD/MM/YEAR", this.dateChange(from));
        temp = temp?.replace("DD/MM/YEAR", this.dateChange(to));
        let mailData = { to: toData, subject: closerMail.name, html: temp };
        let postData = {
          uid: userId,
          mail: mailData,
          text: {
            type: closerMail.name,
            send: toData,
            from: this.dateChange(from),
            to: this.dateChange(to),
          },
        };
        await this.props.sendmail(postData);
      } else message.error(PageConst.ce);
    } catch (error) {
      console.log(error);
    }
  };
  selectChange = (e) => this.setState({ disData: e, serror: false });
  selectChange1 = (e) => this.setState({ disData1: e, serror: false });
  selectUI = (name, val, setFieldValue) => {
    try {
      const { serror, dpData, cpData } = this.state;
      let dData = val?.length === 0 ? [] : val;
      let list = name === "person" ? dpData : cpData;
      return (
        <MultiSelect
          name={name}
          defaultValue={dData}
          data={list}
          className={serror ? "empty" : ""}
          onChange={(e) => {
            setFieldValue(name, e);
            name === "person" && this.selectChange(e);
            name !== "person" && this.selectChange1(e);
          }}
        />
      );
    } catch (error) {
      console.log(error);
    }
  }; // onChange = () => this.setState({ checked: !this.state.checked });
  checkDate = (a, b) => {
    try {
      let date = new Date(b)?.getTime();
      let gtm = this.dateChange(a, true);
      let date2 = new Date(gtm)?.getTime();
      if (this.dateChange(date, true) === gtm) return false;
      return date > date2;
    } catch (error) {
      console.log(error);
    }
  };
  selData = (list) => {
    try {
      const { usrData, uaData, idData } = this.state; // const { upsiInfoData, closureData } = this.props;
      let allList = [],
        nameList = []; // if (!upsiInfoData?.uid && !closureData?.id) { let idx1 = idData?.findIndex((b) => b === userId); idx1 > -1 && list.push(uaData[idx1]);}
      list?.forEach((a) => {
        let idx = uaData?.findIndex((b) => b === a);
        if (idx > -1) {
          if (!allList.includes(idData[idx])) {
            allList.push(idData[idx]);
            nameList.push(a);
          }
          let umtch = usrData?.find((s) => s.id === idData[idx]);
          if (umtch?.uid < 68) {
            let urmtch = usrData?.find(
              (s) => umtch.pan === s.pan && s.id !== umtch.id
            );
            if (urmtch?.pan && !allList.includes(urmtch.id)) {
              if (
                (urmtch.uid === 66 && umtch.uid === 67) ||
                (urmtch.uid === 67 && umtch.uid === 66)
              ) {
                let rfdx = idData.findIndex((r) => r === urmtch.id);
                nameList.push(uaData[rfdx]);
                allList.push(urmtch.id); // umtch = usrData?.find((s) => s.id === urmtch.id);
              }
            }
          } else if (umtch?.uid > 68) {
            let ucList = this.addToList("uId", "dpId", idx);
            ucList.forEach((v) => {
              if (!allList.includes(v.id)) {
                nameList.push(v.uname);
                allList.push(v.id);
              }
            });
            // let crlist = cRefList?.filter((c) => c.uId === idData[idx]);
            // crlist?.forEach((b) => {
            //if (b.dpRelationship) {let rdx = idData.findIndex((r) => r === b.dpId);
            //let umt = usrData?.find((s) => s.id === idData[rdx]);
            //if (umt && !allList.includes(idData[rdx])) {nameList.push(uaData[rdx]);allList.push(idData[rdx]);}}});
          }
          // if (umtch?.uid < 68) {
          //   let crlist1 = cRefList?.filter((c) => c.dpId === idData[idx]); // let ucList1 = this.addToList("dpId", "uId", idx);
          //   crlist1?.forEach((b) => {
          //     if (b.dpRelationship) {
          //       let rdx = idData.findIndex((r) => r === b.uId);
          //       let umt = usrData?.find((s) => s.id === idData[rdx]);
          //       if (umt && !allList.includes(idData[rdx])) {
          //         nameList.push(uaData[rdx]);allList.push(idData[rdx]);
          //         let ucList3 = this.addToList("uId", "dpId", rdx);
          //         ucList3.forEach((v) => {if (!allList.includes(v.id)) {nameList.push(v.uname);allList.push(v.id);}});}}});}
        }
      });
      return { names: nameList?.toString(), ids: allList };
    } catch (error) {
      console.log(error);
    }
  };
  addToList = (name, name2, idx) => {
    try {
      const { idData, uaData, cRefList, usrData } = this.state;
      let list = [];
      let crlist1 = cRefList?.filter((c) => c[name] === idData[idx]);
      crlist1?.forEach((b) => {
        if (b.dpRelationship) {
          let rdx = idData?.findIndex((r) => r === b[name2]);
          let umt = usrData?.find((s) => s.id === idData[rdx]);
          if (umt) {
            list.push({ id: idData[rdx], uname: uaData[rdx] });
            if (umt.uid < 68) {
              // let ucList2 = this.addToList("dpId", "uId", rdx);
              // ucList2?.forEach((f) => list.push(f));
              let urmtch2 = usrData?.find(
                (s) => umt.pan === s.pan && s.id !== umt.id
              ); // && s.uid === 66
              if (urmtch2?.pan && urmtch2?.uid < 68) {
                let rfdx = idData.findIndex((r) => r === urmtch2.id);
                list.push({ id: idData[rfdx], uname: uaData[rfdx] });
              }
            }
          }
        }
      });
      return list;
    } catch (error) {
      console.log(error);
    }
  };
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      this.setState({ disable: true });
      setTimeout(() => this.setState({ disable: false }), 5000);
      const { disData, disData1, updateUPSI, id, upid } = this.state;
      const { upsiInfoData } = this.props; // if (checked && values.resultDate === "") this.setState({ errDelar: true })
      let diData = disData.concat(disData1);
      if (diData.length > 0) {
        let flag = false;
        if (upsiInfoData?.sdate) {
          flag = this.checkDate(values.from, upsiInfoData?.sdate);
          flag = flag ? flag : this.checkDate(values.to, upsiInfoData?.sdate);
        }
        if (!flag) {
          values.uid = userId;
          if (upid !== 0) values.upid = upid;
          let persons = this.selData(diData);
          values.Selection = persons.names;
          values.sid = persons.ids?.toString();
          delete values.person;
          delete values.person2;
          if (updateUPSI) {
            values.upsiId = id;
            await this.props.updateUpsiInfo({ val: { id: id, approve: 1 } });
          } // if (!checked) values.resultDate = null;
          values.from = this.dateChange(values.from, true);
          values.to = this.dateChange(values.to, true);
          if (values.id) {
            const { closureData } = this.props;
            new Date(values.from)?.toUTCString() ===
              new Date(closureData.from).toUTCString() && delete values.from;
            new Date(values.to)?.toUTCString() ===
              new Date(closureData.to).toUTCString() && delete values.to;
            await this.props.updateClosure(values);
          } else {
            values.isSend = 1;
            await this.props.addClosure(values);
          }
          this.sendToUser(persons.ids, upid, values.from, values.to);
        } else message.error(PageConst.upDate);
      } else this.setState({ serror: true });
      setSubmitting(false);
    } catch (error) {
      console.log(error, "handle error");
    }
  };
  render() {
    const { init, disable, edit, serror } = this.state; //, checked, errDelar
    const { loading, loading2 } = this.props;
    return (
      <Spin spinning={loading || loading2} size="large">
        <LayOut>
          <div className="main anime">
            <div className="head">
              <h4 className="title">
                {edit ? ButtonConst.edit : ButtonConst.add}
                {PageConst.title}
              </h4>
              <Button onClick={() => this.props.history.goBack()}>
                {ButtonConst.back}
              </Button>
            </div>
            <div className="display">
              <Formik
                initialValues={init}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <div className="sameLine">
                      <Label
                        title={PageConst.person + PageConst.dp}
                        className={serror ? "empty" : ""}
                      />
                      <div className="cloDiv">
                        {values.person?.length === 0 &&
                          this.selectUI("person", [], setFieldValue)}
                        {values.person?.length > 0 &&
                          this.selectUI("person", values.person, setFieldValue)}
                        <Button
                          onClick={() => this.seall("person", setFieldValue)}
                        >
                          {ButtonConst.sall}
                        </Button>
                        <Button
                          onClick={() =>
                            this.seall("person", setFieldValue, true)
                          }
                        >
                          {ButtonConst.dsall}
                        </Button>
                      </div>
                    </div>
                    <div className="sameLine">
                      <Label
                        title={PageConst.person + PageConst.cp}
                        className={serror ? "empty" : ""}
                      />
                      <div className="cloDiv">
                        {values.person2?.length === 0 &&
                          this.selectUI("person2", [], setFieldValue)}
                        {values.person2?.length > 0 &&
                          this.selectUI(
                            "person2",
                            values.person2,
                            setFieldValue
                          )}
                        <Button
                          onClick={() => this.seall("person2", setFieldValue)}
                        >
                          {ButtonConst.sall}
                        </Button>
                        <Button
                          onClick={() =>
                            this.seall("person2", setFieldValue, true)
                          }
                        >
                          {ButtonConst.dsall}
                        </Button>
                      </div>
                    </div>
                    <div className="sameLine">
                      <Label
                        title={PageConst.rs}
                        className={
                          errors.closureReason && touched.closureReason
                            ? "empty"
                            : ""
                        }
                      />
                      <Input
                        name="closureReason"
                        value={values.closureReason}
                        onBlur={handleBlur}
                        handleChange={handleChange}
                        tabIndex="2"
                        className={
                          errors.closureReason && touched.closureReason
                            ? "empty"
                            : ""
                        }
                      />
                    </div>
                    <div className="sameLine">
                      <Label
                        title={PageConst.from}
                        className={errors.from && touched.from ? "empty" : ""}
                      />
                      <DatePicker
                        holiday
                        value={values.from}
                        handleChange={handleChange("from")}
                        className={errors.from && touched.from ? "empty" : ""}
                        tabIndex="3"
                      />
                    </div>
                    <div className="sameLine">
                      <Label
                        title={PageConst.to}
                        className={errors.to && touched.to ? "empty" : ""}
                      />
                      <DatePicker
                        holiday
                        value={values.to}
                        handleChange={handleChange("to")}
                        className={errors.to && touched.to ? "empty" : ""}
                        tabIndex="4"
                      />
                    </div>
                    <div className="sameLine">
                      <div></div>
                      {errors.to && touched.to ? (
                        <div className="form-error">{errors.to}</div>
                      ) : null}
                    </div>
                    {/* <div className="sameLine"><Label title={PageConst.frd} /><div className="flex cloDiv">
                        <Checkbox className="mt-0"checked={checked}text={PageConst.yes}onChange={() => this.onChange()}/>
                        <Checkbox className="mt-0"checked={!checked}text={PageConst.no}onChange={() => this.onChange()}/>
                        {checked && <DateTimePicker holiday
                          value={values.resultDate}handleChange={(e) => this.changeDate(e, setFieldValue)}
                          className={errDelar ? "empty" : ""}tabIndex="4"/>}</div></div> */}
                    <div className="sameLine">
                      <div></div>
                      <div className="btnDiv anime">
                        {values.id && (
                          <Button
                            disabled={disable}
                            onClick={() => this.sendToUser2(values)}
                          >
                            {ButtonConst.rsm}
                          </Button>
                        )}
                        <Button disabled={disable} htmlType={"submit"}>
                          {ButtonConst.save}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </LayOut>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.closure.loading,
  loading2: state.smtp.loading,
  isSend: state.smtp.isSend,
  closureData: state.closure.closureData,
  closureList: state.closure.closureList,
  userList: state.user.userList,
  upsiInfoData: state.upsiInfo.upsiInfoData,
  templateList: state.template.templateList,
  connRefList: state.connref.connRefList,
});
const mapDispatchToProps = (dispatch) => ({
  addClosure: (payload) => dispatch(addClosure(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getClosureById: (payload) => dispatch(getClosureById(payload)),
  updateClosure: (payload) => dispatch(updateClosure(payload)),
  getUpsiInfoById: (payload) => dispatch(getUpsiInfoById(payload)),
  updateUpsiInfo: (payload) => dispatch(updateUpsiInfo(payload)),
  getTemplateInfoList: (payload) => dispatch(getTemplateInfoList(payload)),
  sendmail: (payload) => dispatch(sendmail(payload)),
  getConRefList: (payload) => dispatch(getConRefList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddClosure)
);
