export const configVar = {
  BASE_URL: "https://insiderq-api.naapbooks.in/api/",//"http://localhost:81/api/", // "http://192.168.1.176:5008/api/", // "http://localhost:5506/api/", //"https://insiderq-api.naapbooks.com/api/",  //
  MAIN_URI: "https://insiderq-api.naapbooks.in/",//"http://localhost:81/"// "http://192.168.1.176:5008/",  , // 
  // BASE_URL: "http://192.168.1.176:5008/api/",
  // MAIN_URL: "http://192.168.1.176:5008/"
};

export const apiConstant = {
  AUTH_LOGIN: "user/login",
};

export const userConst = {
  GET_USERS: "user",
  GET_USER_ALL: "user/all",
  GET_USER_BY_ID: "user/id/",
  GET_USER_BY_DP: "user/dp/",
  UPDATE_USER: "user/update/",
  UPDATE_PWD_USER: "user/updatePassword/",
  ADD_USER: "user/add",
  DELETE_USER: "user/deletePan/",
  // DELETE_USER: "user/delete/",
};
export const educationConst = {
  GET_EDUCATION_LIST: "education",
  GET_EDUCATION_BY_ID: "education/id/",
  UPDATE_EDUCATION: "education/update/",
  ADD_EDUCATION: "education/add",
  DELETE_EDUCATION: "education/delete/",
};
export const employmentConst = {
  GET_EMPLOYMENT_LIST: "employment",
  GET_EMPLOYMENT_BY_ID: "employment/id/",
  UPDATE_EMPLOYMENT: "employment/update/",
  ADD_EMPLOYMENT: "employment/add",
  DELETE_EMPLOYMENT: "employment/delete/",
};
export const bankConst = {
  GET_BANK_LIST: "bank",
  GET_BANK_BY_ID: "bank/id/",
  GET_BANK_BY_UID: "bank/uid/",
  UPDATE_BANK: "bank/update/",
  ADD_BANK: "bank/add",
  DELETE_BANK: "bank/delete/",
};
export const directorConst = {
  GET_DIRECTOR_LIST: "director",
  GET_DIRECTOR_BY_ID: "director/id/",
  UPDATE_DIRECTOR: "director/update/",
  ADD_DIRECTOR: "director/add",
  DELETE_DIRECTOR: "director/delete/",
};
export const companyConst = {
  GET_COMPANY_LIST: "company",
  GET_COMPANY_BY_ID: "company/id/",
  UPDATE_COMPANY: "company/update/",
  ADD_COMPANY: "company/add",
  DELETE_COMPANY: "company/delete/",
};
export const holdingConst = {
  GET_HOLDING_LIST: "holding",
  GET_HOLDING_BY_ID: "holding/id/",
  GET_HOLDING_BY_UID: "holding/uid/",
  UPDATE_HOLDING: "holding/update/",
  ADD_HOLDING: "holding/add",
  DELETE_HOLDING: "holding/delete/",
  DELETEBYDATE_HOLDING: "holding/deleteByDate/",
};
export const masterConst = {
  GET_MASTER_LIST: "master",
  GET_MASTER_BY_ID: "master/id/",
  UPDATE_MASTER: "master/update",
  ADD_MASTER: "master/add",
  DELETE_MASTER: "master/delete/",
  GET_MASTER_TYPE: "mastertype",
};
export const tradingConst = {
  GET_TRADING_LIST: "Preclearance",
  GET_TRADING_BY_ID: "Preclearance/id/",
  UPDATE_TRADING: "Preclearance/update/",
  ADD_TRADING: "Preclearance/add",
  DELETE_TRADING: "Preclearance/delete/",
};
export const declarationConst = {
  GET_DECLARATION_LIST: "documentmaster",
  GET_DECLARATION_BY_UID: "documentmaster/list/",
  GET_DECLARATION_BY_ID: "documentmaster/id/",
  UPDATE_DECLARATION: "documentmaster/update/",
  ADD_DECLARATION: "documentmaster/add",
  DELETE_DECLARATION: "documentmaster/delete/",
};
export const closureConst = {
  GET_CLOSURE_LIST: "twc",
  GET_CLOSURE_BY_ID: "twc/id/",
  GET_MENYCLOSURE_BY_ID: "twc/getMenyByIds",
  UPDATE_CLOSURE: "twc/update/",
  ADD_CLOSURE: "twc/add",
  DELETE_CLOSURE: "twc/delete/",
};
export const upsiInfoConst = {
  GET_UPSIINFO_LIST: "upsiinfo",
  GET_UPSIINFO_BY_ID: "upsiinfo/id/",
  UPDATE_UPSIINFO: "upsiinfo/update/",
  ADD_UPSIINFO: "upsiinfo/add",
  DELETE_UPSIINFO: "upsiinfo/delete/",
};
export const benpoConst = {
  GET_BENPO: "benpos",
  GET_BENPO_BY_ID: "benpos/id/",
  GET_BENPO_BY_UID: "benpos/uid/",
  UPDATE_BENPO: "benpos/update",
  ADD_BENPO: "benpos/add",
  DELETE_BENPO: "benpos/delete/",
};
export const benpoListConst = {
  GET_BENPO_LIST: "benposlist",
  GET_ALLBENPO_LIST: "benposlist/all",
  GET_BENPO_LIST_BY_ID: "benposlist/id/",
  GET_BENPO_LIST_BY_DAY: "benposlist/getbyDate/",
  UPDATE_BENPO_LIST: "benposlist/update",
  ADD_BENPO_LIST: "benposlist/add",
  ADD_BENPODIF_LIST: "benposlist/adddif",
  DELETE_BENPO_LIST: "benposlist/delete/",
  DELETE_BENPO_DATE: "benposlist/deleteByDate/",
};
export const disclosureConst = {
  GET_DISCLOSURE_LIST: "disclosure",
  GET_DISCLOSURE_BY_ID: "disclosure/id/",
  UPDATE_DISCLOSURE: "disclosure/update/",
  ADD_DISCLOSURE: "disclosure/add",
  DELETE_DISCLOSURE: "disclosure/delete/",
};
export const connReferenceConst = {
  GET_CONNREFERENCE_LIST: "ConnReference",
  GET_CONNREFERENCE_BY_ID: "ConnReference/id/",
  GET_CONNREFERENCE_BY_UID: "ConnReference/uid/",
  GET_CONNREFERENCE_BY_DPID: "ConnReference/dpId/",
  UPDATE_CONNREFERENCE: "ConnReference/update/",
  ADD_CONNREFERENCE: "ConnReference/add",
  DELETE_CONNREFERENCE: "ConnReference/delete/",
};
export const dashboardConst = {
  GET_DASHBOARD_LIST: "dashboard",
  GET_DASHBOARD_GRAPH: "dashboard/graph",
  GET_DASHGRAPH: "dashboard/ugraph",
  GET_DASHPGRAPH: "dashboard/pregraph",
  GET_DASHBOARD_CAL: "dashboard/cal",
  GET_DASHGRAPH_MP: "dashboard/mgraph/",
  GET_DASHGRAPH_YP: "dashboard/ygraph",
  GET_DASHGRAPH_YU: "dashboard/yugraph",
  GET_DASHGRAPH_MU: "dashboard/mugraph/",
};
export const TemplatesConst = {
  GET_TEMPLATE_LIST: "templates",
  GET_TEMPLATE_BY_ID: "templates/id/",
  UPDATE_TEMPLATE: "templates/update/",
  ADD_TEMPLATE: "templates/add",
  DELETE_TEMPLATE: "templates/delete/",
};
export const SMTPConst = {
  GET_SMTP_LIST: "smtp/",
  GET_SMTP_BY_ID: "smtp/id/",
  UPDATE_SMTP: "smtp/update/",
  ADD_SMTP: "smtp/add",
  DELETE_SMTP: "smtp/delete/",
  UPDATE_ACTIVE_SMTP: "smtp/activeUpdate",
  SEND_MAIL: "smtp/sendmail",
};
export const AuditConst = {
  GET_AUDIT_LIST: "auditlogs",
  GET_AUDIT_BY_ID: "auditlogs/id/",
  UPDATE_AUDIT: "auditlogs/update/",
  ADD_AUDIT: "auditlogs/add",
  DELETE_AUDIT: "auditlogs/delete/",
};
export const SubscriptionConst = {
  GET_SUBSCRIPTION_LIST: "subscription",
  GET_SUBSCRIPTION_BY_ID: "subscription/id/",
  UPDATE_SUBSCRIPTION: "subscription/update/",
  ADD_SUBSCRIPTION: "subscription/add",
  DELETE_SUBSCRIPTION: "subscription/delete/",
};
