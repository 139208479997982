import React, { Component } from "react";
import { Tabs, Spin } from "antd";
import { connect } from "react-redux";
import Workbook from "react-excel-workbook";
import { withRouter } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

import { ReportStyle } from "./style";
import { getAuthUserID } from "modules/helper";
import { getBenPoList } from "redux/benpo/actions";
import { getAuditLog } from "redux/auditLog/actions";
import { getCompanyList } from "redux/company/actions";
import { getTradingList } from "redux/trading/actions";
import { getUpsiInfoList } from "redux/upsiInfo/actions";
import { getUserList, getUserByDp } from "redux/user/actions";
import { LayOut, TabsUI, Table, Modal, Button } from "components/Form";
import { getBenPoByDay, getBenPosList } from "redux/benpolist/actions";
import {
  DecConst,
  TabsName,
  DpCpList,
  HoldingKeys,
  UPSIKeys,
  AuditKeys,
} from "./constant";
import DR from "./dr";

var userId = getAuthUserID();
const { TabPane } = Tabs;
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "1",
      co: 0,
      dp: false,
      visible: false,
      rids: [],
      ulist: [],
      blist: [],
      blist1: [],
      blist2: [],
      dpList: [],
      cpList: [],
      uplist: [],
      urlist: [],
      pcList: [],
      prList: [],
      vrList: [],
      company: {},
      mailLogs: [],
      auditLogs: [],
      contraList: [],
    };
  }
  async componentDidMount() {
    try {
      userId = userId ? userId : getAuthUserID();
      await this.props.getCompanyList();
      await this.props.getBenPoList();
      await this.props.getUserList(true);
      await this.props.getAuditLog();
      await this.props.getBenPosList();
      await this.props.getUpsiInfoList();
      await this.props.getTradingList();
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      const {
        companyList,
        benpoList,
        benpoDateData,
        userData,
        upsiInfoList,
        auditLogList,
        userList,
        benposList,
        tradingList,
      } = this.props;
      if (companyList !== prevProps.companyList) {
        let comp = companyList?.filter((a) => a.is_deleted === 0);
        this.setState({ company: comp[0] });
      }
      if (prevProps.tradingList !== tradingList) {
        const { pcList } = this.state; //ulist
        let clist = "[",
          vList = "[";
        let ml = tradingList?.filter(
          (a) => a.is_deleted === 0 && a.type === "Approved"
        );
        pcList?.forEach((k) => {
          let item = userList?.findIndex(
            (b) => b.pan.replace(/-/g, "")?.trim() === k.pan?.trim()
          ); //&& b.uid === 67
          if (item > -1 && userList[item]?.is_deleted === 1) {
            userList[item].name = userList[item]?.name + " (Deleted)";
          }
          let item2 =
            item > -1 &&
            ml?.filter((d) => d.connectedID === userList[item]?.id);
          k.approval = "No";
          let pShare = parseInt(k.prevShare);
          let tShare = parseInt(k.totalShare);
          if (item2?.length === 0) {
            if (pShare > tShare) {
              k.sale = !k.sale ? pShare - tShare : k.sale;
              k.variation = pShare - tShare;
            } else {
              k.buy = !k.buy ? tShare - pShare : k.buy;
              k.variation = tShare - pShare;
            }
            if (k.variation !== 0) clist = clist + JSON.stringify(k) + ",";
          }
          let date = new Date(this.dChange(k.date))?.getTime();
          item2?.forEach((f) => {
            let sDate = new Date(this.dChange(f.startDate))?.getTime();
            let endDate = new Date(this.dChange(f.endDate))?.getTime();
            let prdate = new Date(this.dChange(k.prevDate))?.getTime();
            let v = { fid: f.id },
              s = { fid: f.id };
            if (pShare > tShare) {
              k.sale = !k.sale ? pShare - tShare : k.sale;
              k.variation = !k.variation ? pShare - tShare : k.variation; //k.sale > sno ? k.sale - sno :
            } else {
              k.buy = !k.buy ? tShare - pShare : k.buy;
              k.variation = !k.variation ? tShare - pShare : k.variation; // k.buy > sno ? k.buy - sno : pShare === 0 ? tShare :
            }
            if (sDate <= date && date <= endDate) {
              let sno = parseInt(f.securityNo);
              k.startDate = f.startDate;
              k.endDate = f.endDate;
              k.purpose = f.purpose;
              k.qty = f.securityNo;
              k.approval = "Yes";
              if (f.purpose === "Sale") {
                let sl = pShare - tShare;
                if (sno > sl && sl > 0) {
                  if (sDate <= prdate && prdate <= endDate) {
                    if (pShare < k.prevShare2) {
                      let sr = k.prevShare2 - pShare;
                      if (sno > sl + sr && sl > 0) {
                        k.variation = sno - (sl + sr);
                        s = k;
                      } else if (sno !== sl + sr) {
                        k.variation = k.prevShare2 - tShare;
                        k.prevShare = k.prevShare2;
                        k.sale = sl + sr;
                        v = k;
                      }
                    } else if (sno !== sl) s = k;
                  } else if (sno !== sl) s = k;
                } else if (sno !== sl) v = k;
              } else if (f.purpose === "Buy") {
                let sl = tShare - pShare;
                if (sno > sl && sl > 0) {
                  if (sDate <= prdate && prdate <= endDate) {
                    if (pShare > k.prevShare2) {
                      let sr = pShare - k.prevShare2;
                      if (sno > sl + sr && sl > 0) {
                        k.variation = sno - (sl + sr);
                        s = k;
                      } else if (sno !== sl + sr) {
                        k.variation = tShare - k.prevShare2;
                        k.prevShare = k.prevShare2;
                        k.buy = sl + sr;
                        v = k;
                      }
                    } else if (sno !== sl) s = k;
                  } else if (sno !== sl) s = k;
                } else if (sno !== sl) v = k;
              }
            }
            // else {var mysDate = new Date(f.startDate);//   mysDate.setDate(mysDate.getDate() - 5);//   let smDate = new Date(this.dChange(mysDate))?.getTime();
            //   let myeDate = new Date(f.endDate);//   myeDate.setDate(myeDate.getDate() + 5);//   let emDate = new Date(this.dChange(myeDate))?.getTime();
            //   if (smDate <= date && date <= emDate) {k.startDate = f.startDate;k.endDate = f.endDate;k.purpose = f.purpose;k.qty = f.securityNo;k.approval = "Yes";}}
            v = k;
            if (k.variation !== 0) {
              if (v?.pan && !clist.includes(`"id":${v.id}`))
                clist = clist + JSON.stringify(v) + ",";
              if (s?.pan && !vList.includes(`"id":${s.id}`))
                vList = vList + JSON.stringify(s) + ",";
            }
            k.approval = "No";
            k.purpose = "";
            k.qty = "";
            k.startDate = "";
            k.endDate = "";
          });
        });
        let prData = JSON.parse((clist + "]")?.replace(",]", "]"));
        let vlData = JSON.parse((vList + "]")?.replace(",]", "]"));
        let trdList = [];
        let trdList2 = [];
        ml?.forEach((r) => {
          let usl = userList?.findIndex(
            (q) => q.id === r.connectedID && q.uid === 67
          );
          if (usl > -1) r.pan = userList[usl].pan?.replace("-", "");
          let stDate = new Date(this.dChange(r.startDate))?.getTime();
          let edDate = new Date(this.dChange(r.endDate))?.getTime();
          let mysDate = new Date(r.startDate);
          mysDate.setDate(mysDate.getDate() - 5);
          let myeDate = new Date(r.endDate);
          myeDate.setDate(myeDate.getDate() + 5);
          let smDate = new Date(this.dChange(mysDate))?.getTime();
          let emDate = new Date(this.dChange(myeDate))?.getTime();
          let bnlist = benposList.filter(
            (g) => g.pan?.trim() === r.pan?.trim()
          );
          bnlist?.forEach((g) => {
            let bdate = new Date(this.dChange(g.date))?.getTime();
            if (stDate <= bdate && bdate <= edDate) {
              g.startDate = r.startDate;
              g.endDate = r.endDate;
              g.purpose = r.purpose;
              g.qty = r.securityNo;
              trdList.push(g);
            } else if (smDate <= bdate && bdate <= emDate) {
              g.startDate = r.startDate;
              g.endDate = r.endDate;
              g.purpose = r.purpose;
              g.qty = r.securityNo;
              trdList2.push(g);
            }
          });
        });
        let trdSort = trdList?.sort((a, b) => a.date?.localeCompare(b.date));
        trdSort?.forEach((j) => {
          j.approval = "Yes";
          let type = "";
          let pShare = parseInt(j.prevShare);
          let tShare = parseInt(j.totalShare);
          if (pShare > tShare) {
            j.sale = !j.sale ? pShare - tShare : j.sale;
            j.variation = pShare - tShare;
            type = "Sale";
          } else if (pShare < tShare) {
            type = "Buy";
            j.buy = !j.buy ? tShare - pShare : j.buy;
            j.variation = tShare - pShare;
          }
          let prInx = prData?.findIndex(
            (w) =>
              w.startDate === j.startDate &&
              w.endDate === j.endDate &&
              w.id === j.id
          );
          let vlIdx = vlData?.findIndex(
            (w) =>
              w.startDate === j.startDate &&
              w.endDate === j.endDate &&
              w.id === j.id
          );
          let vlIdx1 = vlData?.findIndex((w) => w.date === j.date);
          let prFLt = prData?.filter(
            (w) =>
              w.uid === j.uid &&
              w.startDate === j.startDate &&
              w.endDate === j.endDate &&
              new Date(w.date)?.getTime() < new Date(j.date)?.getTime()
          );
          let prldx = prData?.findIndex(
            (w) =>
              w.startDate === j.startDate &&
              w.endDate === j.endDate &&
              w.uid === j.uid
          );
          let prSame = prData?.findIndex((q) => q.id === j.id);
          if (!j.variation || j.variation === 0) {
            j.variation = 0;
            j.prevShare = j.totalShare;
            if (vlIdx === -1) vlData.push(j);
            if (prInx > -1) prData.splice(prInx, 1);
          } else if (j.variation !== 0) {
            if (parseInt(j.qty) < j.variation && prInx === -1) {
              if (prSame === -1) prData.push(j);
              else {
                if (vlIdx1 > -1) prData.splice(prSame, 1);
                else if (prFLt?.length > 0) prData[prSame] = this.getEmtObj(j);
                else prData[prSame] = j;
              }
            } else if (prInx > -1) {
              if (parseInt(j.qty) === j.variation) {
                if (vlIdx === -1 && prFLt?.length === 0)
                  prData.splice(prInx, 1);
                else if (
                  prData[prInx]?.date !== vlIdx[vlIdx]?.date ||
                  prFLt?.length > 0
                )
                  prData[prInx] = this.getEmtObj(prData[prInx]);
              } else if (parseInt(j.qty) > j.variation) {
                if (vlIdx === -1 && j.purpose === type) vlData.push(j);
                j.purpose === type && prData.splice(prInx, 1);
              } else {
                prData[prInx].qty = j.qty;
                prData[prInx].approval = "Yes";
                prData[prInx].endDate = j.endDate;
                prData[prInx].purpose = j.purpose;
                prData[prInx].startDate = j.startDate;
              }
            } else if (parseInt(j.qty) > j.variation && j.purpose === type) {
              if (vlIdx === -1) vlData.push(j);
              if (prSame !== -1) prData.splice(prSame, 1);
              if (
                prldx !== -1 &&
                new Date(prData[prldx].date)?.getTime() >
                  new Date(j.date)?.getTime()
              )
                prData[prldx] = this.getEmtObj(prData[prldx]);
            } else if (parseInt(j.qty) === j.variation)
              if (prSame > -1 && prFLt?.length === 0) prData.splice(prSame, 1);
          }
        });
        let trdSort2 = trdList2?.sort((a, b) => a.date?.localeCompare(b.date));
        trdSort2?.forEach((j) => {
          let pShare = parseInt(j.prevShare);
          let tShare = parseInt(j.totalShare);
          if (pShare > tShare) {
            j.sale = !j.sale ? pShare - tShare : j.sale;
            j.variation = pShare - tShare;
          } else if (pShare < tShare) {
            j.buy = !j.buy ? tShare - pShare : j.buy;
            j.variation = tShare - pShare;
          } else if (pShare === tShare) j.variation = 0;
          let prInx = prData?.findIndex(
            (w) =>
              w.id === j.id ||
              (w.startDate === j.startDate && w.endDate === j.endDate)
          );
          let vlIdx = vlData?.findIndex(
            (w) =>(w.startDate === j.startDate && w.endDate === j.endDate));
          if (j.variation !== 0 && vlIdx === -1) {
            if (prInx === -1) {
              j.approval = "Yes";
              prData.push(j);
            } else if (JSON.stringify(prData[prInx]) !== JSON.stringify(j)) {
              prData[prInx].qty = j.qty;
              prData[prInx].approval = "Yes";
              prData[prInx].endDate = j.endDate;
              prData[prInx].purpose = j.purpose;
              prData[prInx].startDate = j.startDate;
            }
          }
        });
        let prSort = prData?.sort((a, b) => b.date?.localeCompare(a.date));
        let vlSort = vlData?.sort((a, b) => b.date?.localeCompare(a.date));
        prSort?.forEach((kf) => {
          let usrl = userList?.filter((su) => su.name === kf.name);
          if (usrl?.length === 1 && usrl[0]?.is_deleted === 1) {
            kf.name = kf.name + " (Deleted)";
          }
        });
        vlSort?.forEach((kf) => {
          let usrl = userList?.filter((su) => su.name === kf.name);
          if (usrl?.length === 1 && usrl[0]?.is_deleted === 1) {
            kf.name = kf.name + " (Deleted)";
          }
        });
        this.setState({ prList: prSort, vrList: vlSort });
      }
      if (benposList !== prevProps.benposList) {
        let bList = benposList?.filter((a) => a.is_deleted === 0);
        let bData = [],
          cData = [],
          uDate = [];
        let utrlist = userList.filter((x) => x.uid === 67);
        utrlist?.forEach((a) => {
          let pan = a.pan.replace(/-/g, "")?.trim(),
            share = -1,
            share1 = 0,
            share2 = 0,
            buy = 0,
            sale = 0,
            quality = 0,
            ctshare = 0,
            type = "",
            pdate = "",
            pdate2 = "",
            chng = "",
            dpid = "",
            flag1 = false,
            flag2 = false,
            flag3 = false;
          let item = bList?.filter((b) => b.pan?.trim() === pan);
          let item2 = item?.sort((a, b) => a?.date?.localeCompare(b?.date));
          if (item2?.length > 0) {
            item2?.forEach((d, i) => {
              if (i === 0) ctshare = d.totalShare;
              else if (ctshare !== d.totalShare) flag3 = true;
            });
            if (!flag3 && item2?.length < benpoList.length - 3) {
              item2[0]["prevShare"] = 0;
              bData.push(item2[0]);
            } else {
              item2?.forEach((c) => {
                let tshare = parseInt(c.totalShare);
                let obj = {
                  category: c.category,
                  date: c.date,
                  name: c.name,
                  pan: c.pan,
                  dpid: c.dpid,
                  quality: tshare,
                };
                if (share === -1) {
                  share = tshare;
                  share1 = tshare;
                  pdate = c.date;
                  dpid = c.dpid;
                  c.prevShare = 0;
                  c.prevDate = c.date;
                  obj.buy = tshare;
                  obj.type = "Buy";
                  uDate.push(obj);
                } else if (share !== tshare || share1 !== tshare) {
                  if (dpid === c.dpid) {
                    c.prevShare = share1;
                    c.prevShare2 = share2;
                    c.prevDate = pdate;
                    c.prevDate2 = pdate2;
                    bData.push(c);
                    if (share1 > tshare) {
                      flag1 = true;
                      chng = chng === "" ? "Sale" : chng;
                      obj.sale = share1 - tshare;
                      obj.type = "Sale";
                      uDate.push(obj);
                    } else if (share1 < tshare) {
                      flag2 = true;
                      chng = chng === "" ? "Buy" : chng;
                      obj.buy = tshare - share1;
                      obj.type = "Buy";
                      uDate.push(obj);
                    }
                    if (share1 !== tshare) {
                      if (type === "") type = share > tshare ? "Sale" : "Buy";
                      else {
                        quality = tshare;
                        if (type === "Sale" && share1 < tshare) {
                          buy = tshare - share1;
                        } else if (type === "Buy" && share1 > tshare)
                          sale = share1 - tshare;
                      }
                      share1 = tshare;
                      share2 = c.prevShare;
                      pdate = c.date;
                      pdate2 = c.prevDate;
                      if (flag1 && flag2 && item[0]) {
                        if (chng === "Buy") flag1 = false;
                        else if (chng === "Sale") flag2 = false;
                        let cnt = {
                          name: item[0].name,
                          pan: item[0].pan?.trim(),
                        };
                        if (item[0].category) cnt.category = item[0].category;
                        cnt.buy = buy;
                        cnt.sale = sale;
                        cnt.quality = quality;
                        a.uid === 67 && cData.push(cnt);
                      }
                    }
                  }
                } else {
                  c.prevShare = share1;
                  c.prevShare2 = share2;
                  c.prevDate = pdate;
                  c.prevDate2 = pdate2;
                  share1 = tshare;
                  share2 = c.prevShare;
                  pdate = c.date;
                  pdate2 = c.prevDate;
                  dpid = c.dpid;
                }
              });
            }
          }
        });
        let ctList = [];
        let contList = uDate;
        let veri = "";
        let veriArr = [];
        contList?.forEach((t) => {
          if (veriArr.length === 0 || !veriArr?.find((z) => z.pan === t.pan)) {
            let cList = uDate?.filter((q) => q.pan === t.pan);
            if (cList.length > 1) veriArr.push({ pan: t.pan, list: cList });
          }
        });
        // veriArr?.forEach((t) => {
        //   for (let i = 0; i < t.list.length; i++) {
        //     if (i === 0) {veri = t.list[i];let w = new Date(t.list[i]?.date);six = w.setMonth(w?.getMonth() + 6);
        //     } else if (t.list[i].type !== veri.type) {
        //       if (six > new Date(veri.date).getTime()) ctList.push(t.list[i]);
        //       else {veri = t.list[i];let w = new Date(t.list[i]?.date);six = w.setMonth(w?.getMonth() + 6);}}}});
        veriArr?.forEach((t) => {
          for (let i = 0; i < t.list.length; i++) {
            if (i === 0) veri = t.list[i];
            else if (t.list[i].type !== veri.type) {
              let w = new Date(t.list[i].date);
              let six = w.setMonth(w.getMonth() - 6);
              if (six < new Date(veri.date).getTime()) ctList.push(t.list[i]);
            } //else veri = t.list[i];
            veri = t.list[i];
          }
        });
        let vctSort = ctList?.sort((a, b) => b.date?.localeCompare(a.date));
        this.setState({ contraList: vctSort, pcList: bData });
      }
      if (userData !== prevProps.userData) {
        const { blist1, blist2, dp } = this.state;
        let ulist = userData?.filter((a) => a.is_deleted === 0);
        let data = [],
          gdata = [];
        ulist?.forEach((a) => {
          if (a.uid !== 66 && a.uid !== 68) {
            let item = blist1?.find((b) => b.pan?.trim() === a.pan?.trim());
            let item2 = blist2?.find((b) => b.pan?.trim() === a.pan?.trim());
            if (item?.pan) {
              item.nowhold = item.totalShare;
              item.date3 = item.date;
            }
            if (item2?.pan) {
              item2.nowhold = item2.totalShare;
              item2.date3 = item2.date;
            }
            if (item && item2) {
              item.preHolding = item2.totalShare;
              item.date4 = item2.date;
              let it = parseInt(item.totalShare);
              let it2 = parseInt(item2.totalShare);
              item.selling = it2 > it ? it2 - it : 0;
              item.buying = it > it2 ? it - it2 : 0;
              data.push(item);
            } else if ((!dp && a.uid === 67) || (dp && a.uid === 69)) {
              if (item?.pan || item2?.pan) {
                item?.pan && data.push(item);
                item2?.pan && data.push(item2);
              } else gdata.push(a);
            }
          }
        });
        gdata?.forEach((j) => (j.name = j.name + " " + j.surname));
        let adata = data?.concat(gdata);
        if (!dp) {
          this.setState({ ulist: userData, dpList: adata, dp: true });
          await this.props.getUserByDp(0);
        } else this.setState({ cpList: adata });
      }
      if (benpoList !== prevProps.benpoList) {
        let list = benpoList?.filter((a) => a.is_deleted === 0);
        let dates = [];
        list?.forEach((a) => dates.push(this.dChange(a.date, true)));
        dates.sort((a, b) => b.localeCompare(a));
        dates?.length > 1 && (await this.props.getBenPoByDay(dates[0]));
        this.setState({ blist: list });
        dates?.length > 1 && (await this.props.getBenPoByDay(dates[1]));
      }
      if (benpoDateData !== prevProps.benpoDateData) {
        const { blist1, company } = this.state;
        let list = benpoDateData?.filter((a) => a.is_deleted === 0);
        let share = company?.totalshare ? company.totalshare : 0;
        list?.forEach(
          (a) => (a.equity = ((a.totalShare * 100) / share)?.toFixed(2))
        );
        if (blist1.length === 0) this.setState({ blist1: list });
        else {
          this.setState({ blist2: list });
          await this.props.getUserByDp(1);
        }
      }
      if (upsiInfoList !== prevProps.upsiInfoList) {
        const { urlist, rids, co } = this.state;
        let uplist = upsiInfoList?.filter((a) => a.is_deleted === 0);
        let rec = [],
          dp = [];
        uplist?.forEach((b) => {
          if(b.isInfoSend){
            const sender=b.receiver;
            b.receiver=b.sender;
            b.sender=sender;
          }
          if (!rec.includes(b.receiver)) {
            let s = b.receiver?.split(",");
            s.forEach((t) => rec.push(t));
          }
          if (!dp.includes(b.dpName)) {
            if (rids.includes(b.dpId)) {
              let idx = rids.findIndex((f) => f === b.dpId);
              if (!dp.includes(urlist[idx])) dp.push(urlist[idx]);
              b.dpName = urlist[idx];
              if (new Date(b?.created_date) < new Date("2022/05/19")) {
                if (b.dpId === co) b.dpName = "Niharika Shah (CO)";
                if (b?.receiverId.includes(this.state.co)) {
                  let recv = b.receiver.replace(
                    "CS Sahul Jotaniya (CO)",
                    "Niharika Shah (CO)"
                  );
                  b.receiver = recv;
                }
              }
            } else dp.push(b.dpName);
          }
        });
        let srtData = uplist.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        this.setState({ uplist: srtData });
      }
      if (auditLogList !== prevProps.auditLogList) {
        const { urlist, rids, co } = this.state;
        auditLogList?.forEach((a) => {
          let inx = rids?.findIndex((b) => b === a.uid);
          if (inx === -1 || userList[inx]?.uid > 67)
            inx = rids?.findIndex((b) => b === a.toid);
          if (inx > -1) {
            a.user = urlist[inx];
            if (
              rids[inx] === co &&
              new Date(a?.created_date) < new Date("2022/05/19")
            )
              a.user = "Niharika Shah (CO)";
          }
        });
        let srtData = auditLogList.sort(
          (a, b) => new Date(b?.created_date) - new Date(a?.created_date)
        );
        let al = srtData?.filter((a) => a.text.toLowerCase() !== "email sent");
        let el = srtData?.filter((a) => a.text.toLowerCase() === "email sent");
        this.setState({ auditLogs: al, mailLogs: el });
      }
      if (userList !== prevProps.userList) {
        let data = [],
          rids = []; // let urlist = userList?.filter((a) => a.is_deleted === 0); // && a.uid !== 68
        userList?.forEach((a) => {
          a.value = a.surname ? a.name + " " + a.surname : a.name;
          if (a.uid === 66 && a.id === 59) this.setState({ co: a.id });
          if (a.is_deleted) a.value = a.value + " (Deleted) ";
          let pos =
            a.uid === 66
              ? " (CO"
              : a.uid === 67
              ? " (DP"
              : a.uid === 68
              ? " (PCS"
              : " (CP";
          if (data.includes(a.value + pos + ")")) {
            if (data.includes(a.value + pos + ") 2")) {
              if (data.includes(a.value + pos + ") 3"))
                data.push(a.value + pos + ") 4");
              else data.push(a.value + pos + ") 3");
            } else data.push(a.value + pos + ") 2");
          } else data.push(a.value + pos + ")");
          rids.push(a.id);
        });
        this.setState({ urlist: data, rids });
      }
    } catch (error) {
      console.log(error);
    }
  }
  getEmtObj = (obct) => {
    obct.qty = "";
    obct.startDate = "";
    obct.endDate = "";
    obct.purpose = "";
    obct.approval = "No";
    return obct;
  };
  setObjtoOtr = (g, r) => {
    g.startDate = r.startDate;
    g.endDate = r.endDate;
    g.purpose = r.purpose;
    g.qty = r.securityNo;
    return g;
  };
  setOfDate = (date) => new Date(this.dChange(date))?.getTime();
  dChange = (date, fm) => {
    let day = new Date(date).getDate();
    day = day?.toString()?.length === 1 ? "0" + day : day;
    let month = new Date(date).getMonth() + 1;
    month = month?.toString()?.length === 1 ? "0" + month : month;
    let year = new Date(date).getFullYear();
    if (fm) return year + "-" + month + "-" + day;
    return date ? month + "-" + day + "-" + year : "";
  };
  tabPaneUI = () => {
    try {
      const {
        blist1,
        dpList,
        cpList,
        uplist,
        auditLogs,
        mailLogs,
        contraList,
        prList,
        vrList,
      } = this.state;
      let hdData = blist1;
      if (cpList?.length > 0) hdData = dpList?.concat(cpList);
      let srtData = hdData.sort((g, h) => g.date?.localeCompare(h.date));
      return TabsName.map((a, i) => {
        return (
          <TabPane tab={a} key={i + 1}>
            <div className="display">
              {i === 0 && <Table type="dpr" data={dpList} />}
              {i === 1 && <Table type="cpr" data={cpList} />}
              {i === 2 && (
                <DR data={contraList} prList={prList} vrList={vrList} />
              )}
              {i === 3 && <Table type="hs" data={srtData} />}
              {i === 4 && <Table data={uplist} type="upsiInfo" noAction />}
              {i === 5 && <Table data={auditLogs} type="audit" noAction />}
              {i === 6 && (
                <Table
                  data={mailLogs}
                  type="audit"
                  viewCol={this.toggleModal}
                />
              )}
            </div>
          </TabPane>
        );
      });
    } catch (error) {
      console.log(error);
    }
  };
  toggleModal = (a) =>
    this.setState({ visible: !this.state.visible, mData: a ? a : null });
  callback = (value) => this.setState({ key: value });
  excleUI = () => {
    try {
      const { dpList, cpList, blist1, key, uplist, auditLogs, mailLogs } =
        this.state;
      let hdData = blist1;
      if (cpList?.length > 0) hdData = dpList?.concat(cpList);
      const passData =
        key === "1" || key === "2"
          ? key === "2"
            ? cpList
            : dpList
          : key === "4"
          ? hdData
          : key === "5"
          ? uplist
          : key === "6"
          ? auditLogs
          : key === "7"
          ? mailLogs
          : [];
      passData?.forEach((a, i) => (a.key = i + 1));
      return (
        <Workbook
          filename={TabsName[key - 1] + ".xlsx"}
          element={
            <Button className="exclebtn">
              <DownloadOutlined />
            </Button>
          }
        >
          {key === "1" || key === "2" ? (
            <Workbook.Sheet data={passData} name={"report"}>
              {DpCpList.map((a, i) => (
                <Workbook.Column label={a.label} value={a.value} key={i} />
              ))}
            </Workbook.Sheet>
          ) : key === "4" ? (
            <Workbook.Sheet data={passData} name={"report"}>
              {HoldingKeys.map((a, i) => (
                <Workbook.Column label={a.label} value={a.value} key={i} />
              ))}
            </Workbook.Sheet>
          ) : key === "5" ? (
            <Workbook.Sheet data={passData} name={"report"}>
              {UPSIKeys.map((a, i) => (
                <Workbook.Column label={a.label} value={a.value} key={i} />
              ))}
            </Workbook.Sheet>
          ) : (
            (key === "6" || key === "7") && (
              <Workbook.Sheet data={passData} name={"report"}>
                {AuditKeys.map((a, i) => (
                  <Workbook.Column label={a.label} value={a.value} key={i} />
                ))}
              </Workbook.Sheet>
            )
          )}
        </Workbook>
      );
    } catch (error) {
      console.log(error);
    }
  };
  ckeckData = (key) => {
    try {
      const { dpList, cpList, blist1, uplist, auditLogs, mailLogs } =
        this.state;
      let hdData = blist1;
      if (cpList?.length > 0) hdData = dpList?.concat(cpList);
      const passData =
        key === "1" || key === "2"
          ? key === "2"
            ? cpList
            : dpList
          : key === "4"
          ? hdData
          : key === "5"
          ? uplist
          : key === "6"
          ? auditLogs
          : key === "7"
          ? mailLogs
          : [];
      return passData.length > 0;
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { loading, loading2, loading3, loading4, loading5 } = this.props;
    const { visible, mData, key } = this.state;
    return (
      <Spin
        size="large"
        spinning={loading || loading2 || loading3 || loading4 || loading5}
      >
        <LayOut>
          <ReportStyle className="main anime">
            <div className="head">
              <h4 className="title">{DecConst.dec}</h4>
              {this.ckeckData(key) && key !== "3" && (
                <div className="btnDiv">{this.excleUI()}</div>
              )}
            </div>
            <div className="mainTabDIV">
              <TabsUI callback={this.callback}>{this.tabPaneUI()}</TabsUI>
            </div>
          </ReportStyle>
        </LayOut>
        {visible && (
          <Modal Report data={mData} toggleModal={this.toggleModal} />
        )}
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.benpo.loading,
  loading2: state.benpolist.loading,
  benpoList: state.benpo.benpoList,
  loading3: state.user.loading,
  userData: state.user.userData,
  userList: state.user.userList,
  loading4: state.upsiInfo.loading,
  loading5: state.auditLog.loading,
  companyList: state.company.companyList,
  benposList: state.benpolist.benposList,
  tradingList: state.trading.tradingList,
  upsiInfoList: state.upsiInfo.upsiInfoList,
  auditLogList: state.auditLog.auditLogList,
  benpoDateData: state.benpolist.benpoDateData,
});
const mapDispatchToProps = (dispatch) => ({
  getUserByDp: (payload) => dispatch(getUserByDp(payload)),
  getUserList: (payload) => dispatch(getUserList(payload)),
  getAuditLog: (payload) => dispatch(getAuditLog(payload)),
  getBenPoList: (payload) => dispatch(getBenPoList(payload)),
  getBenPoByDay: (payload) => dispatch(getBenPoByDay(payload)),
  getBenPosList: (payload) => dispatch(getBenPosList(payload)),
  getCompanyList: (payload) => dispatch(getCompanyList(payload)),
  getTradingList: (payload) => dispatch(getTradingList(payload)),
  getUpsiInfoList: (payload) => dispatch(getUpsiInfoList(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
